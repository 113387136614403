import React, {useEffect, useState} from "react"


import firebase from 'firebase/app';

import 'firebase/auth';



function login(cb) {
    var provider = new firebase.auth.GithubAuthProvider();

    provider.addScope('read:user,user:email');
    console.log("Calling ")
    firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
            /** @type {firebase.auth.OAuthCredential} */
            var credential = result.credential;

            // This gives you a GitHub Access Token. You can use it to access the GitHub API.
            var token = credential.accessToken;

            // The signed-in user info.
            var user = result.user;
            console.log(result)
            cb({token, result})
            // ...
        }).catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
            console.error(error);
        });
}

function App() {
    const [loginResult, setLoginResult] = useState({});

    function loginView() {
        return <>
            <h1>Login to Github</h1>
            <button onClick={() => {
                login(
                    ({token, result}) => { 

                        setLoginResult(result);
                    }
                )
            }}>Login</button>
        </>
    }

    function loggedinView() {
        return <>
            <h1>Logged In</h1>
            <p>
                Hi {loginResult.additionalUserInfo.username}!
            </p>
        </>
    }
    

    return <div>
        {loginResult.additionalUserInfo == undefined ? loginView() : loggedinView() }
    </div>
}

export default App;