import React from "react"
import ReactDOM from "react-dom"
import App from "./App"

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
var firebaseConfig = {
    apiKey: "AIzaSyA8WVw8r9mTpxxiHWjwfY9_A-G3wrOMLtM",
    authDomain: "forge-hotg-dev.firebaseapp.com",
    projectId: "forge-hotg-dev",
    storageBucket: "forge-hotg-dev.appspot.com",
    messagingSenderId: "163797597652",
    appId: "1:163797597652:web:0a8305f5a56d07b60b6a41",
    measurementId: "G-VJVF69ZE9W"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();




ReactDOM.render(<App />, document.getElementById("root"))